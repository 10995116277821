import { memo } from 'react';
import { PopupCardMemo, PopupCardProps } from '../popup-card/popup-card';
import { CardScrollerMemo } from '../card-scroller/card-scroller';
import { CardListHeaderMemo } from '../card-list-header/card-list-header';
import styles from './popup-card-list.module.css';

export type PopupCardListItemProps = Pick<
  PopupCardProps,
  'type' | 'id' | 'name' | 'launchUrl' | 'imageUrl' | 'videoUrl' | 'shortDescription' | 'categories'
>;

export type PopupCardListProps = {
  id: string;
  items: PopupCardListItemProps[];
  headerTitle: string;
  headerLink?: string;
  headerIcon?: React.ReactNode;
  headerSize?: 'medium' | 'large';
  cardSize?: 'medium' | 'large';
  cardOrientation?: 'landscape' | 'portrait';
  showRanking?: boolean;
};

export function PopupCardList({
  id,
  items,
  headerTitle,
  headerLink,
  headerIcon,
  headerSize,
  cardSize,
  cardOrientation,
  showRanking = false,
}: PopupCardListProps) {
  const rootId = `card-list-${id}`;
  const headerId = `card-list-header-${id}`;
  const scrollerId = `card-list-scroller-${id}`;

  // No items, no render.
  if (items.length === 0) return null;

  return (
    <div id={rootId} className={styles.root} role="region" aria-labelledby={headerId}>
      <div className={styles.scrollToTarget}></div>
      <CardListHeaderMemo
        id={headerId}
        className={styles.header}
        title={headerTitle}
        link={headerLink}
        icon={headerIcon}
        size={headerSize}
      />
      <CardScrollerMemo className={styles.cards} scrollerId={scrollerId}>
        {items.map((item, index) => (
          <PopupCardMemo
            key={index}
            type={item.type}
            id={item.id}
            name={item.name}
            launchUrl={item.launchUrl}
            imageUrl={item.imageUrl}
            videoUrl={item.videoUrl}
            shortDescription={item.shortDescription}
            categories={item.categories}
            size={cardSize}
            orientation={cardOrientation}
            rank={showRanking ? index + 1 : undefined}
          />
        ))}
      </CardScrollerMemo>
    </div>
  );
}

export const PopupCardListMemo = memo(PopupCardList);
