import { useRef, memo, ReactNode } from 'react';
import { Button } from '../../buttons/button/button';
import { ChevronLeftIcon } from '../../svg-icons/chevron-left-icon';
import { ChevronRightIcon } from '../../svg-icons/chevron-right-icon';
import { useCardScroller } from '../../../hooks/use-card-scroller';
import {
  ARROW_NAV_CONTAINER_CLASS,
  ARROW_NAV_CONTAIN_ATTRIBUTE,
  ARROW_NAV_IGNORE_ATTRIBUTE,
} from '../../../utils/nav-system';
import { SCROLL_RESTORATION_TARGET_CLASS } from '../../../constants';
import styles from './card-scroller.module.css';

export type CardScrollerProps = {
  className?: string;
  scrollerId?: string;
  children?: ReactNode;
};

export function CardScroller({
  className = '',
  scrollerId = '',
  children = null,
}: CardScrollerProps) {
  const scrollerRef = useRef<HTMLDivElement>(null);
  const scrollContentRef = useRef<HTMLDivElement>(null);
  const scrollIndicatorThumbRef = useRef<HTMLDivElement>(null);
  const { canScroll, canScrollBack, canScrollForward, scrollToPrevBatch, scrollToNextBatch } =
    useCardScroller(scrollerRef, scrollContentRef, scrollIndicatorThumbRef);

  return (
    <div className={`${styles.root} ${className}`}>
      <div className={styles.prev} data-active={canScrollBack ? 'true' : 'false'}>
        <Button
          className={styles.button}
          theme="SecondaryDark"
          tabIndex={-1}
          disabled={!canScrollBack}
          onClick={scrollToPrevBatch}
        >
          <ChevronLeftIcon />
        </Button>
      </div>
      <div
        ref={scrollerRef}
        id={scrollerId}
        className={`${styles.scroller} ${SCROLL_RESTORATION_TARGET_CLASS} ${ARROW_NAV_CONTAINER_CLASS}`}
        {...{
          [ARROW_NAV_CONTAIN_ATTRIBUTE]: 'x',
          [ARROW_NAV_IGNORE_ATTRIBUTE]: 'y',
        }}
      >
        <div ref={scrollContentRef} className={styles.content}>
          {children}
        </div>
      </div>
      <div className={styles.next} data-active={canScrollForward ? 'true' : 'false'}>
        <Button
          className={styles.button}
          theme="SecondaryDark"
          tabIndex={-1}
          disabled={!canScrollForward}
          onClick={scrollToNextBatch}
        >
          <ChevronRightIcon />
        </Button>
      </div>
      <div className={styles.scrollIndicator} data-active={canScroll ? 'true' : 'false'}>
        <div ref={scrollIndicatorThumbRef} className={styles.scrollIndicatorThumb}></div>
      </div>
    </div>
  );
}

export const CardScrollerMemo = memo(CardScroller);
